﻿import $ from 'jquery';

$(document).ready(function () {
    $('button.navbar-toggle').click(function () {
        var picMinHeight = "45px";
        var $picture = $('div.DivLogoStandard>a>img');

        if ($picture.css("height") === picMinHeight) {
            setTimeout(function () { $picture.css("height", "100%"); }, 500);
        } else {
            $picture.css("height", picMinHeight);
        }
    });


    (function() {

        var docHtml = document.documentElement,
            $divLogo = $('.DivLogoStandard'),
            startScrollHeight = 100,
            scrollDelay = 250,
            scrolled = false;
        
        function scroll() {
            if (!scrolled) {
                scrolled = true;
                setTimeout(scrollSite, scrollDelay);
            }
        }

        function scrollSite() {
            if (scrollValue() >= startScrollHeight) {                
                $divLogo.addClass("DivLogoSmall");
            } else {
                $divLogo.removeClass("DivLogoSmall");
            }
            scrolled = false;
        }

        function scrollValue() {
            return window.pageYOffset || docHtml.scrollTop;
        }

        window.addEventListener('scroll', function() { scroll(); }, false);
        scroll();

    })();
});